<template>
  <div class="nexo-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="3" sm="12">
            <h1>{{ $t('NEXO_TRANSACTION_LIST_PAGE_TITLE') }}</h1>
          </CCol>
          <CCol md="6" sm="12"></CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchNexoTransactionHeader :onSearch="onSearch" :typeList="typeList" :statusList="statusList" />
        <NexoTransactionListTable :limit="limit" :activePage="activePage" :items="items" :total="total" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { endpoints, env, Constants } from '@/constants';
import NexoTransactionListTable from '@/components/NexoTransaction/NexoTransactionListTable';
import SearchNexoTransactionHeader from '@/components/NexoTransaction/SearchNexoTransactionHeader';
import StakingPagination from '@/components/StakingPagination';
import stringHelper from '@/utils/string-helper';
import responseHelper from '@/utils/response-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'NexoTransactionList',
  components: {
    SearchNexoTransactionHeader,
    NexoTransactionListTable,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      typeList: [],
      statusList: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    await Promise.all([this.getTypes(), this.getStatuses()]);
    this.isLoading = false;
  },
  methods: {
    async getTypes() {
      try {
        const result = await this.$http.get(endpoints.getNexoTransactionsTypes);
        this.typeList = stringHelper.translateTextForDropdownList(result.data, 'NEXO_TRANSACTION_TYPE');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NEXO_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getStatuses() {
      try {
        const result = await this.$http.get(endpoints.getNexoTransactionsStatuses);
        this.statusList = stringHelper.translateTextForDropdownList(result.data, 'NEXO_TRANSACTION_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NEXO_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },

    async getNexoTransaction(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          email: filterData.email,
          address: filterData.address,
          type: filterData.type,
          status: filterData.status,
          tx_id: filterData.tx_id,
          from_date: filterData.dateRange.startDate,
          to_date: dateTimeHelper.adjustToDate(filterData.dateRange.endDate),
        };
        const result = await this.$http.get(endpoints.getNexoTransactions, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NEXO_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getNexoTransaction();
    },

    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getNexoTransaction();
    },

    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getNexoTransaction(data);
    },
  },
};
</script>

<style lang="scss">
.nexo-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
