var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nexo-table-container"},[_c('CCard',{staticClass:"nexo-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"nexo-transaction-type","text":item.type}})],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"nexo-transaction-status","text":item.status}})],1)]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,_vm.dateFormat)))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }