<template>
  <div class="nexo-table-container">
    <CCard class="nexo-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #type="{item}">
          <td>
            <StakingBadge type="nexo-transaction-type" :text="item.type" />
          </td>
        </template>
        <template #status="{item}">
          <td>
            <StakingBadge type="nexo-transaction-status" :text="item.status" />
          </td>
        </template>
        <template #createdAt="{item}">
          <td>{{ item.createdAt | moment(dateFormat) }}</td>
        </template>
        <template #action="{item}">
          <td width="50px">
            <button class="btn btn-view-details" @click="viewDetails(item)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';

export default {
  name: 'NexoTransactionListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'email',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_EMAIL_COL'),
          _style: 'min-width: 80px;',
        },
        {
          key: 'address',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_ADDRESS_COL'),
          _style: 'min-width: 80px;',
        },
        {
          key: 'type',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_TYPE_COL'),
          _style: 'min-width: 80px;',
        },
        {
          key: 'status',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_STATUS_COL'),
          _style: 'min-width: 80px;',
        },
        {
          key: 'platform',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_PLATFORM_COL'),
          _style: 'min-width: 80px;',
        },
        {
          key: 'tx_id',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_TX_ID_COL'),
        },
        {
          key: 'createdAt',
          label: this.$t('NEXO_TRANSACTION_LIST_TABLE_COMPONENT_CREATE_AT_COL'),
          _style: 'min-width: 80px;',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`nexo-transactions/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.nexo-table-container {
  .nexo-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .nexo-image {
        width: 30px;
      }

      .fixed-rate-icon {
        color: #5da30b;
      }

      .nexo-transaction-type,
      .nexo-transaction-status {
        background-color: transparent;
        padding: 0px;
      }
    }
  }
}
</style>

